/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type Status = "CREATED" | "FAILED" | "PROCESSED" | "PROCESSING" | "%future added value";
export type TourCardSubscriptionVariables = {
    tourId: string;
};
export type TourCardSubscriptionResponse = {
    readonly tourProgressUpdated: {
        readonly id: string;
        readonly status: Status;
        readonly text: string | null;
        readonly thumbnailURL: string | null;
    };
};
export type TourCardSubscription = {
    readonly response: TourCardSubscriptionResponse;
    readonly variables: TourCardSubscriptionVariables;
};



/*
subscription TourCardSubscription(
  $tourId: ID!
) {
  tourProgressUpdated(tourId: $tourId) {
    id
    status
    text
    thumbnailURL
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tourId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "tourId",
        "variableName": "tourId"
      }
    ],
    "concreteType": "TourProgress",
    "kind": "LinkedField",
    "name": "tourProgressUpdated",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "thumbnailURL",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TourCardSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TourCardSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "629266e653e4670eb3d8171717290af7",
    "id": null,
    "metadata": {},
    "name": "TourCardSubscription",
    "operationKind": "subscription",
    "text": "subscription TourCardSubscription(\n  $tourId: ID!\n) {\n  tourProgressUpdated(tourId: $tourId) {\n    id\n    status\n    text\n    thumbnailURL\n  }\n}\n"
  }
};
})();
(node as any).hash = '5d42e747309c1f1deda74c80e0e38237';
export default node;
