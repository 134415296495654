/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ToursRefetchQueryVariables = {
    count?: number | null;
    cursor?: unknown | null;
};
export type ToursRefetchQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"Tours_tours">;
};
export type ToursRefetchQuery = {
    readonly response: ToursRefetchQueryResponse;
    readonly variables: ToursRefetchQueryVariables;
};



/*
query ToursRefetchQuery(
  $count: Int
  $cursor: Cursor
) {
  ...Tours_tours
}

fragment TourCard_tour on Tour {
  id
  title
  views
  createdAt
  description
  videoURL
  thumbnailURL
  reels {
    provider
    downloadURL
    id
  }
  status {
    status
    id
  }
  logs {
    status
    error
    id
  }
}

fragment Tours_tours on Query {
  tours(first: $count, after: $cursor) {
    edges {
      cursor
      node {
        id
        status {
          status
          id
        }
        ...TourCard_tour
        __typename
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ToursRefetchQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Tours_tours"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ToursRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TourConnection",
        "kind": "LinkedField",
        "name": "tours",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TourEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Tour",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TourProgress",
                    "kind": "LinkedField",
                    "name": "status",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "views",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "videoURL",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "thumbnailURL",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Reel",
                    "kind": "LinkedField",
                    "name": "reels",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "provider",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "downloadURL",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TourLog",
                    "kind": "LinkedField",
                    "name": "logs",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "error",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": null,
        "handle": "connection",
        "key": "Tours_tours",
        "kind": "LinkedHandle",
        "name": "tours"
      }
    ]
  },
  "params": {
    "cacheID": "57ad05d8f2efad8a932eae4b537601f3",
    "id": null,
    "metadata": {},
    "name": "ToursRefetchQuery",
    "operationKind": "query",
    "text": "query ToursRefetchQuery(\n  $count: Int\n  $cursor: Cursor\n) {\n  ...Tours_tours\n}\n\nfragment TourCard_tour on Tour {\n  id\n  title\n  views\n  createdAt\n  description\n  videoURL\n  thumbnailURL\n  reels {\n    provider\n    downloadURL\n    id\n  }\n  status {\n    status\n    id\n  }\n  logs {\n    status\n    error\n    id\n  }\n}\n\nfragment Tours_tours on Query {\n  tours(first: $count, after: $cursor) {\n    edges {\n      cursor\n      node {\n        id\n        status {\n          status\n          id\n        }\n        ...TourCard_tour\n        __typename\n      }\n    }\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '8100466ebea3ce5c98d7a5f91e8b5820';
export default node;
