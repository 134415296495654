/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TourPlayerCanvas_tour = {
    readonly id: string;
    readonly title: string;
    readonly description: string;
    readonly videoURL: string;
    readonly thumbnailURL: string;
    readonly videoInfo: {
        readonly width: number;
        readonly height: number;
        readonly fullSize: number;
        readonly mediumSize: number;
        readonly smallSize: number;
    };
    readonly markers: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly description: string;
        readonly scrollPosition: number;
        readonly frameIndex: number;
    }>;
    readonly clips: ReadonlyArray<{
        readonly id: string;
        readonly index: number;
        readonly frameCount: number;
        readonly duration: number;
        readonly highURI: string;
        readonly lowURI: string;
    }>;
    readonly " $refType": "TourPlayerCanvas_tour";
};
export type TourPlayerCanvas_tour$data = TourPlayerCanvas_tour;
export type TourPlayerCanvas_tour$key = {
    readonly " $data"?: TourPlayerCanvas_tour$data;
    readonly " $fragmentRefs": FragmentRefs<"TourPlayerCanvas_tour">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./TourPlayerCanvasTodoRefetchQuery.graphql.ts'),
      "identifierField": "id"
    }
  },
  "name": "TourPlayerCanvas_tour",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "videoURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TourVideoInfo",
      "kind": "LinkedField",
      "name": "videoInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "width",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "height",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullSize",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mediumSize",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "smallSize",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Marker",
      "kind": "LinkedField",
      "name": "markers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "scrollPosition",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "frameIndex",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Clip",
      "kind": "LinkedField",
      "name": "clips",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "index",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "frameCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "duration",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "highURI",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lowURI",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Tour",
  "abstractKey": null
};
})();
(node as any).hash = 'a363ded2a9b600dca6c8cdfb2f91f4d3';
export default node;
