import {createTheme, DeprecatedThemeOptions} from '@mui/material/styles';

export const theme: DeprecatedThemeOptions = createTheme({
  // overrides: {
  //   MuiPaper: {
  //     rounded: {
  //       borderRadius: '16px',
  //     },
  //   },
  //   MuiSvgIcon: {
  //     fontSizeLarge: {
  //       color: '#fff',
  //     },
  //   },
  //   MuiButton: {
  //     contained: {
  //       borderRadius: '25px',
  //     },
  //   },
  // },

  spacing: 10,
  shape: {borderRadius: 20},
  palette: {
    background: {default: '#f7f7f7', paper: '#fff'},
    primary: {
      main: '#5374FC',
      light: '#BEC8CF',
      dark: '#5374FC79',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#E6EAF8',
      light: '#BEC8CF',
      dark: '#06468219',
      contrastText: '#95A5B2',
    },
    error: {
      main: '#FC5353',
      light: '#FC53531A',
      contrastText: '#95A5B2',
    },
    text: {
      primary: '#191C1F',
      secondary: '#758089',
    },
    divider: '#EDEFF2',
  },

  typography: {
    h1: {fontSize: '2em', fontWeight: 700},
    h2: {
      fontWeight: 700,
      fontSize: '1.3rem',
    },
    h3: {
      fontWeight: 400,
      fontSize: '1.4rem',
      color: '#758089',
    },
    // h4: {
    //   fontWeight: 300,
    // },
    // h5: {},
    // h6: {
    //   lineHeight: 2.3,
    //   fontWeight: 600,
    // },
    // subtitle1: {
    //   fontFamily: 'Roboto',
    //   fontWeight: 'bold',
    //   fontSize: '10vh',
    //   textShadow: '0px 0px 40px #161615C9',
    //   WebkitTextStroke: `1px #5374FC`,
    // },
    // subtitle2: {},
    // caption: {
    //   fontSize: 'small',
    // },
    // button: {
    //   fontWeight: 600,
    // },
    // overline: {
    //   fontFamily: 'Roboto',
    //   fontWeight: 'bold',
    //   fontSize: '1em',
    // },
    body1: {
      lineHeight: '1.5em',
    },
    // body2: {
    //   fontFamily: 'Roboto',
    //   fontWeight: 'bold',
    //   fontSize: '1em',
    // },
  },
});
