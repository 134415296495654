import React, {KeyboardEvent, useCallback, useState} from 'react';
import InputBase, {InputBaseProps} from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import {InputAdornment, InputLabel, styled, Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {addShouldNotForwardProps} from '../../utils/addShouldNotForwardProps';

export interface TruvuTextFieldBaseProps extends InputBaseProps {
  label?: string;
  isPassword?: boolean;
  helperText?: string;
  displayOnly?: boolean;
  shrinkLabel?: boolean;
  endIcon?: React.ReactNode;
  onEnter?: (event: KeyboardEvent<HTMLInputElement>) => void;
}

interface StyledInputControlProps {
  $multiline?: boolean;
  $label?: boolean;
}

const StyledInputControl = styled(FormControl, {
  shouldForwardProp: addShouldNotForwardProps('$multiline', '$label'),
})<StyledInputControlProps>(({$multiline, $label}) => ({
  backgroundColor: '#EDEFF2',
  color: '#191C1F',
  borderRadius: '16px',
  ...($label
    ? {padding: $multiline ? '20px 13px 13px' : '20px 0'}
    : {padding: $multiline ? '13px' : '0'}),
  position: 'relative',
  paddingRight: '13px',
  '& input': {
    padding: '20px',
    fontSize: '16px',
    borderRadius: '15px',
    color: '#191C1F',
    '&::placeholder': {
      fontWeight: 500,
    },
    '&:focus': {
      border: 'none',
      /* Change the border color */
      outline: 'none',
      /* Remove the default outline (focus) style */
      /* Add any other styles you want for the focused state */
    },
  },
}));

const StyledErrorTypography = styled(Typography)(({theme}) => ({
  position: 'absolute',
  bottom: 0,
  left: '15px',
  color: theme.palette.error.main,
  zIndex: 1,
}));

const StyledHelperTypography = styled(Typography)(({theme}) => ({
  marginTop: '-10px',
  marginBottom: '-10px',
  color: theme.palette.text.secondary,
}));
const StyledPasswordButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: '50%',
  right: 13,
  transform: 'translateY(-50%)',
}));

export function TruvuTextFieldBase({
  label,
  name,
  id,
  helperText,
  isPassword,
  displayOnly,
  shrinkLabel = false,
  onEnter,
  endIcon,
  ...props
}: TruvuTextFieldBaseProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const toggleShowPassword = useCallback(() => {
    setShowPassword((prevState) => !prevState);
  }, []);

  const inputId = id ?? name ?? 'truvu-textfield';

  const handleEnterCallback = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (onEnter != null && event.key === 'Enter') {
        onEnter(event);
      }
    },
    [onEnter]
  );

  return (
    <StyledInputControl
      $label={!!label}
      $multiline={props.multiline}
      fullWidth={props.fullWidth}
    >
      {label ? (
        <InputLabel
          htmlFor={inputId}
          variant={'filled'}
          shrink={shrinkLabel || displayOnly}
        >
          {label}
        </InputLabel>
      ) : null}
      {displayOnly ? (
        <Typography
          id={inputId}
          px={1.3}
          pt={1.5}
          pb={1}
          sx={{cursor: 'default'}}
        >
          {props.value as string}
        </Typography>
      ) : (
        <InputBase
          type={isPassword && !showPassword ? 'password' : 'text'}
          inputProps={{'aria-label': label}}
          id={inputId}
          onKeyDown={onEnter != null ? handleEnterCallback : undefined}
          {...props}
        />
      )}
      {endIcon != null && (
        <InputAdornment
          position="end"
          sx={{position: 'absolute', top: '50%', right: 13}}
        >
          {endIcon}
        </InputAdornment>
      )}
      {isPassword && (
        <StyledPasswordButton onClick={toggleShowPassword}>
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </StyledPasswordButton>
      )}
      {props.error && helperText != null ? (
        <StyledErrorTypography variant="caption">
          {helperText}
        </StyledErrorTypography>
      ) : helperText != null ? (
        <StyledHelperTypography variant="caption">
          {helperText}
        </StyledHelperTypography>
      ) : null}
    </StyledInputControl>
  );
}
