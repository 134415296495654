/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Status = "CREATED" | "FAILED" | "PROCESSED" | "PROCESSING" | "%future added value";
export type TourCard_tour = {
    readonly id: string;
    readonly title: string;
    readonly views: number;
    readonly createdAt: unknown;
    readonly description: string;
    readonly videoURL: string;
    readonly thumbnailURL: string;
    readonly reels: ReadonlyArray<{
        readonly provider: string;
        readonly downloadURL: string;
    }>;
    readonly status: {
        readonly status: Status;
    };
    readonly logs: ReadonlyArray<{
        readonly status: string;
        readonly error: string;
    }>;
    readonly " $refType": "TourCard_tour";
};
export type TourCard_tour$data = TourCard_tour;
export type TourCard_tour$key = {
    readonly " $data"?: TourCard_tour$data;
    readonly " $fragmentRefs": FragmentRefs<"TourCard_tour">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./TourCardRefetchQuery.graphql.ts'),
      "identifierField": "id"
    }
  },
  "name": "TourCard_tour",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "views",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "videoURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Reel",
      "kind": "LinkedField",
      "name": "reels",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "provider",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "downloadURL",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TourProgress",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TourLog",
      "kind": "LinkedField",
      "name": "logs",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "error",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Tour",
  "abstractKey": null
};
})();
(node as any).hash = '69e3d9c28b75d444050217d1e0849414';
export default node;
