import React from 'react';
import ReactDOM from 'react-dom';
import {ThemeProvider, StyledEngineProvider} from '@mui/material/styles';
import {theme} from './theme/DefaultTheme';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import * as SentryBrowser from '@sentry/browser';

Sentry.init({
  dsn:
    'https://bad783bc4bdf8aae936e645c275c9207@o4506067566723072.ingest.sentry.io/4506067569541120',
  integrations: [
    SentryBrowser.browserTracingIntegration(),
    // SentryBrowser.feedbackIntegration({
    //   colorScheme: 'system',
    //   buttonLabel: 'Feedback',
    //   formTitle: 'Send Feedback',
    //   messagePlaceholder:
    //     "What's the bug? What did you expect? What's your feedback?",
    //   submitButtonLabel: 'Send Feedback',
    //   themeLight: {borderRadius: '100px'},
    //   themeDark: {borderRadius: '100px'},
    // }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: process.env.NODE_ENV === 'production',
});

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
