import {Terms} from '../components/TermsComponent';

export const complianceWithECTAct: Terms = [
  {
    id: '11.1',
    term: 'Full name:',
  },
  {
    id: '11.2',
    term: 'Registration number:',
  },
  {
    id: '11.3',
    term: 'Physical address:',
  },
  {
    id: '11.4',
    term: 'Fax number:',
  },
  {
    id: '11.5',
    term: 'Telephone number:',
  },
  {
    id: '11.6',
    term: 'Website address: www.truvy.app',
  },
  {
    id: '11.7',
    term: 'E-mail address:',
  },
  {
    id: '11.8',
    term: 'Names of office bearers:',
  },
  {
    id: '11.9',
    term: 'Registered at:',
  },
];
