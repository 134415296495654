import React, {useCallback} from 'react';
import Stack from '@mui/material/Stack';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {useMutation} from 'react-relay-mutation';
import graphql from 'babel-plugin-relay/macro';
import {UploadTourDetails} from './UploadTourDetails';
import {TourInterface} from '../../../context/TourContext';
import {TruvuDialog, useTruvuDialog} from '../../../components/dialog';
import {UploadTourVideoGetPreSignedUrlMutation} from '../../../__generated__/UploadTourVideoGetPreSignedUrlMutation.graphql';
import {UploadTourVideoSubmitPreSignedUploadMutation} from '../../../__generated__/UploadTourVideoSubmitPreSignedUploadMutation.graphql';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import {TruvuMessageDialog} from '../../../components/dialog/TruvuMessageDialog';
import {useHistory} from 'react-router';
import {
  ProgressBar,
  UploadTourProgressDialog,
} from './UploadTourProgressDialog';

const DefaultErrorMessage = 'Your tour failed to upload. Tour upload failed';
const DefaultRetryMessage = 'Retry';

export function UploadTourVideo() {
  const {push} = useHistory();
  const [tour, setTour] = React.useState<TourInterface | undefined>();
  const [uploadProgress, setUploadProgress] = React.useState<number>(0);
  const [retryMessage, setRetryMessage] = React.useState<string>(
    DefaultRetryMessage
  );
  const [errorMessageTitle, setErrorMessageTitle] = React.useState<string>(
    DefaultErrorMessage
  );
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const {
    openDialog: openDetailsDialog,
    onOpenDialog: onOpenDetailsDialog,
    onCloseDialog: onCloseDetailsDialog,
  } = useTruvuDialog(false);
  const {
    openDialog: openUploadProgressDialog,
    onOpenDialog: onOpenUploadProgressDialog,
    onCloseDialog: onCloseUploadProgressDialog,
  } = useTruvuDialog(false);
  const {
    openDialog: openUploadErrorDialog,
    onOpenDialog: onOpenUploadErrorDialog,
    onCloseDialog: onCloseUploadErrorDialog,
  } = useTruvuDialog(false);

  const [
    fileGetPreSignedUrl,
  ] = useMutation<UploadTourVideoGetPreSignedUrlMutation>(
    graphql`
      mutation UploadTourVideoGetPreSignedUrlMutation(
        $input: FileGetPreSignedUrlInput!
      ) {
        fileGetPreSignedUrl(input: $input) {
          uploadURL
          key
          headers {
            key
            value
          }
          error
          message
        }
      }
    `
  );

  const [
    fileSubmitPreSignedUpload,
  ] = useMutation<UploadTourVideoSubmitPreSignedUploadMutation>(
    graphql`
      mutation UploadTourVideoSubmitPreSignedUploadMutation(
        $input: FileSubmitPreSignedUploadInput!
      ) {
        fileSubmitPreSignedUpload(input: $input) {
          bucketURL
          tourId
        }
      }
    `
  );

  const onUpload = React.useCallback(
    async (file: File) => {
      onCloseUploadErrorDialog();
      onCloseDetailsDialog();
      onOpenUploadProgressDialog();

      try {
        const response = await fileGetPreSignedUrl({
          variables: {input: {text: 's'}},
        });

        if (response.fileGetPreSignedUrl.error) {
          onCloseUploadProgressDialog();
          onOpenUploadErrorDialog();
          setErrorMessageTitle(DefaultErrorMessage);
          setRetryMessage(DefaultRetryMessage);
          setErrorMessage('No active license for company');
          return;
        }

        const headers = response.fileGetPreSignedUrl.headers.reduce(
          (acc: Record<string, string>, header) => {
            acc[header.key] = header.value;
            return acc;
          },
          {}
        );

        const s3Response = await axios.put(
          response.fileGetPreSignedUrl.uploadURL,
          file,
          {
            headers: headers,
            onUploadProgress: (progressEvent) => {
              const percentCompleted = progressEvent.total
                ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
                : 0;
              setUploadProgress(percentCompleted);
            },
          }
        );

        if (s3Response.status !== 200) {
          throw new Error(
            `S3 upload failed with status ${s3Response.status}: ${s3Response.data}`
          );
        }

        const submissionResponse = await fileSubmitPreSignedUpload({
          variables: {
            input: {
              key: response.fileGetPreSignedUrl.key,
            },
          },
        });

        setTour({
          videoURL: submissionResponse.fileSubmitPreSignedUpload.bucketURL,
          id: btoa(
            'Tour:' + submissionResponse.fileSubmitPreSignedUpload.tourId
          ),
        });

        onCloseUploadProgressDialog();
        onOpenDetailsDialog();
      } catch (error) {
        onOpenUploadErrorDialog();
        setErrorMessageTitle(DefaultErrorMessage);
        setRetryMessage(DefaultRetryMessage);
        setErrorMessage(error.message);
      }
    },
    [
      fileGetPreSignedUrl,
      fileSubmitPreSignedUpload,
      onCloseDetailsDialog,
      onCloseUploadErrorDialog,
      onCloseUploadProgressDialog,
      onOpenDetailsDialog,
      onOpenUploadErrorDialog,
      onOpenUploadProgressDialog,
    ]
  );

  const onValidateUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file == null) {
        return;
      }

      /** Check video length */
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        const duration = video.duration;
        if (duration > 60 * 3) {
          setErrorMessageTitle('Tour Video Length exceeded');
          setRetryMessage('Upload shorter video');
          setErrorMessage('Video cannot be longer than 3 minutes');
          onOpenUploadErrorDialog();
        } else {
          onUpload(file);
        }
      };

      video.src = URL.createObjectURL(file);
    },
    [onOpenUploadErrorDialog, onUpload]
  );

  return (
    <Stack position={'sticky'} bottom={0} py={2} spacing={1} zIndex={1000}>
      <UploadTourProgressDialog
        isOpen={openUploadProgressDialog}
        ProgressBar={
          <ProgressBar variant="determinate" value={uploadProgress} />
        }
      />
      <TruvuMessageDialog
        title={errorMessageTitle}
        variant="error"
        message={<Typography>{errorMessage}</Typography>}
        actions={
          <>
            <TruvuButton
              variant="primary"
              onClick={() => {
                onCloseDetailsDialog();
              }}
              accept="video/mp4,video/x-m4v,video/*"
              InputProps={{onChange: onValidateUpload}}
              upload
            >
              {retryMessage}
            </TruvuButton>
            <TruvuButton
              variant="secondary"
              onClick={() => {
                onCloseDetailsDialog();
                push('/');
              }}
            >
              Return to Tours
            </TruvuButton>
          </>
        }
        isOpen={openUploadErrorDialog}
        onOpen={onOpenUploadErrorDialog}
        onClose={onCloseUploadErrorDialog}
      />
      <TruvuDialog
        open={openDetailsDialog}
        onClose={onCloseDetailsDialog}
        title="Tour Info"
      >
        <UploadTourDetails
          onCloseDetailsDialog={onCloseDetailsDialog}
          tour={tour}
          setTour={setTour}
        />
      </TruvuDialog>
      <TruvuButton
        variant="primary"
        accept="video/mp4,video/x-m4v,video/*"
        InputProps={{onChange: onValidateUpload}}
        upload
      >
        Upload a video
      </TruvuButton>
      {/*<TruvuButton*/}
      {/*  variant="secondary"*/}
      {/*  accept="video/mp4,video/x-m4v,video/*"*/}
      {/*  InputProps={{capture: 'camera', onChange: onUpload}}*/}
      {/*  upload*/}
      {/*>*/}
      {/*  Use phone camera*/}
      {/*</TruvuButton>*/}
    </Stack>
  );
}
