import React from 'react';
import {ToursPageQuery} from '../__generated__/ToursPageQuery.graphql';
import RelayRenderer from '../RelayRenderer';
import {Tours} from '../modules/tourLanding/components';
import graphql from 'babel-plugin-relay/macro';
import {Add} from '@mui/icons-material';
import {TruvuMenu} from '../components/TruvuMenu';
import {TruvuToursContainer} from '../components/containers/TruvuToursContainer';
import {checkUploadLimit} from '../modules/tourLanding/helpers/checkUploadLimit';
import {TruvuIconButton} from '../components/button/TruvuIconButton';

const query = graphql`
  query ToursPageQuery($count: Int = 10, $cursor: Cursor) {
    ...Tours_tours
    invites {
      edges {
        cursor
        node {
          id
        }
      }
    }
    me {
      user {
        ...TruvuToursContainer_user
        ...Tours_user
        company {
          license {
            planName
            monthlyActiveTours
            monthlyUploadedTours
            uploadLimit
            quantity
          }
        }
      }
    }
  }
`;

export function ToursPage() {
  return (
    <RelayRenderer<ToursPageQuery> query={query}>
      {(data) => {
        const {isActiveAvailable, isUploadAvailable} = checkUploadLimit({
          monthlyActiveTours:
            data.me?.user?.company?.license?.monthlyActiveTours,
          monthlyUploadedTours:
            data.me?.user?.company?.license?.monthlyUploadedTours,
          uploadLimit: data.me?.user?.company?.license?.uploadLimit,
          quantity: data.me?.user?.company?.license?.quantity,
        });
        const disableAdd =
          data.me?.user?.company?.license == null ||
          !isActiveAvailable ||
          !isUploadAvailable;
        return (
          <TruvuToursContainer userRef={data.me?.user}>
            <TruvuMenu
              title="My Tours"
              action={
                <TruvuIconButton
                  variant={disableAdd ? 'default' : 'primary'}
                  label="Add"
                  to="/upload"
                  disabled={disableAdd}
                >
                  <Add />
                </TruvuIconButton>
              }
              sticky
            />
            <Tours data={data} />
          </TruvuToursContainer>
        );
      }}
    </RelayRenderer>
  );
}
