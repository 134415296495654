import React from 'react';
import * as yup from 'yup';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {Form, FormOnSubmit} from '../../../components/form/Form';
import TruvuTextField from '../../../components/textField';
import {useSnackbarContext} from '../../../context/SnackbarContext';
import {useMutation} from 'react-relay-mutation';
import {UploadTourDetailsMutation} from '../../../__generated__/UploadTourDetailsMutation.graphql';
import graphql from 'babel-plugin-relay/macro';
import {TourInterface} from '../../../context/TourContext';
import {useHistory} from 'react-router';

interface UploadTourDetailsForm {
  title: string;
  description?: string;
}

const validationSchema = yup.object({
  title: yup
    .string()
    .required('Title is required.')
    .max(50, 'Maximum characters reached'),
  description: yup.string().max(300, 'Maximum characters reached'),
});

interface UploadTourDetailsProps {
  onCloseDetailsDialog(): void;
  tour: TourInterface | undefined;
  setTour: React.Dispatch<React.SetStateAction<TourInterface | undefined>>;
}

export function UploadTourDetails({
  onCloseDetailsDialog,
  tour,
  setTour,
}: UploadTourDetailsProps) {
  const history = useHistory();
  const {notify} = useSnackbarContext();

  const [tourSave] = useMutation<UploadTourDetailsMutation>(
    graphql`
      mutation UploadTourDetailsMutation($input: TourSaveInput!) {
        tourSave(input: $input) {
          tour {
            id
            title
            description
            videoURL
            listingLink
          }
        }
      }
    `,
    {
      onCompleted: () => {
        notify({message: 'Data saved', variant: 'success'});
      },
    }
  );

  const handleSubmit = React.useCallback<FormOnSubmit<UploadTourDetailsForm>>(
    async (values, {setSubmitting}) => {
      setSubmitting(true);
      try {
        if (tour?.id) {
          const response = await tourSave({
            variables: {
              input: {
                id: tour?.id,
                title: values.title,
                description: values.description ?? '',
                videoURL: tour?.videoURL ?? '',
                listingLink: '',
              },
            },
          });
          if (response) {
            setTour((currentTour) => ({
              ...currentTour,
              id: response.tourSave.tour.id,
            }));
          }
        }
        onCloseDetailsDialog();
        history.push(`/`);
      } catch (e) {
        onCloseDetailsDialog();
        notify({
          message: 'Could not save tour data: \n' + e.message,
          variant: 'warning',
        });
      } finally {
        setSubmitting(false);
      }
    },
    [
      history,
      notify,
      onCloseDetailsDialog,
      setTour,
      tour?.id,
      tour?.videoURL,
      tourSave,
    ]
  );

  return (
    <Form<UploadTourDetailsForm>
      initialValues={{title: '', description: ''}}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <TruvuTextField name="title" label="Title" multiline formikField />
      <TruvuTextField
        name="description"
        label="Description"
        multiline
        formikField
      />
      <TruvuButton variant="primary" formikSubmit>
        Done
      </TruvuButton>
    </Form>
  );
}
