/* eslint-disable */
// @ts-nocheck

import {hasWebCodec} from './util';

export async function printIndex(v, idx: number, canvas: HTMLCanvasElement) {
  const w = (canvas.width = v[0].frame.codedWidth);
  const h = (canvas.height = v[0].frame.codedHeight);
  try {
    const context = canvas?.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.canvas.width = w;
    context.canvas.height = h;
    if (hasWebCodec()) {
      const image = await LibAVWebCodecs.createImageBitmap(v[idx].frame);
      context.drawImage(image, 0, 0);
    } else {
      await LibAVWebCodecs.canvasDrawImage(
        context,
        v[idx].frame,
        0,
        0,
        canvas.width,
        canvas.height
      );
    }
  } catch (e) {
    console.log({e});
  }
}
