import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import useLocalStorage from 'react-use/lib/useLocalStorage';

enum UserRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
}
export interface AuthImageObject {
  id: string;
  uri: string;
  title: string;
  altText?: string;
}

export type AuthContextType =
  | {
      token: string;
      authenticated: true;
      role: UserRole;
    }
  | {
      authenticated: false;
    };

export interface AuthContextStateType {
  auth: AuthContextType;
  handleLogin: (
    username: string,
    password: string,
    source: 'truvu' | 'google'
  ) => void;
  handleLogout: () => void;
  hasRole(role: UserRole): boolean;
}

export const AuthContext = createContext<AuthContextStateType>({
  auth: {authenticated: false},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleLogin: async () => new Promise(() => {}),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleLogout: () => {},
  hasRole: () => true,
});
interface Props {
  children: JSX.Element;
}
export function AuthContextProvider({children}: Props) {
  const loggingOut = useRef(false);
  const authTokenLoaded = React.useRef<boolean>(false);

  const [localAuth, setLocalAuth] = useLocalStorage<AuthContextType>('auth', {
    authenticated: false,
  });
  const [auth, setAuth] = useState<AuthContextType>(() => {
    authTokenLoaded.current = true;
    return localAuth ?? {authenticated: false};
  });

  const handleLogin = React.useCallback(
    async function (
      username: string,
      token: string,
      source: 'truvu' | 'google'
    ) {
      localStorage.setItem('prevLoginMethod', source);

      const newAuth = {
        token,
        authenticated: true,
        role: UserRole.USER,
      };
      setAuth(newAuth);
      setLocalAuth(newAuth);

      // TODO handle timeout
    },
    [setLocalAuth]
  );

  const handleLogout = useCallback(() => {
    if (auth.authenticated === true) {
      setAuth({authenticated: false});
      setLocalAuth({authenticated: false});
    }
  }, [auth.authenticated, setLocalAuth]);

  useEffect(() => {
    if (loggingOut.current) {
      handleLogout();
      loggingOut.current = false;
    }
  }, [loggingOut, handleLogout]);

  useEffect(() => {
    let innerAuth: AuthContextType = {authenticated: false};

    // TODO: Localize expiry
    if (localAuth != null) {
      innerAuth = localAuth;

      setAuth(innerAuth);
    }
  }, [localAuth, auth]);

  const hasRole = React.useCallback(
    (role: UserRole) => {
      if (!auth.authenticated) {
        return false;
      }

      if (role === auth.role) {
        return true;
      }

      // if (auth.role.indexOf(role) !== -1) {
      //   return true;
      // }

      return false;
    },
    [auth]
  );

  const value = React.useMemo(
    () => ({auth, handleLogin, handleLogout, hasRole}),
    [auth, handleLogin, handleLogout, hasRole]
  );

  // Prevent double render before auth token loaded
  if (!authTokenLoaded.current) {
    return null;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContextProvider;
export const useAuthContext = () => useContext(AuthContext);
