import React from 'react';
import Dialog from '@mui/material/Dialog';
import {Close} from '@mui/icons-material';
import {IconButton, Stack, useMediaQuery, Zoom} from '@mui/material';
import {makeStyles, createStyles} from '@mui/styles';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {Theme} from '@mui/material/styles';
import {TruvuMenu} from '../../TruvuMenu';

const Transition = React.forwardRef(function Transition(
  {
    children,
    ...props
  }: TransitionProps & {
    children: React.ReactElement<unknown, string>;
  },
  ref: React.Ref<unknown>
) {
  const isMobile = useMediaQuery((t: Theme) => t.breakpoints.down('md'));

  if (isMobile) {
    return (
      <Slide direction="up" ref={ref} {...props}>
        {children}
      </Slide>
    );
  }
  return (
    <Zoom ref={ref} {...props}>
      {children}
    </Zoom>
  );
});

export const useTruvuDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'rgba(25,28,31,0.6)',
    },
    paper: {
      backgroundColor: theme.palette.background.default,
      width: '100%',
      maxWidth: '450px',
      margin: 0,
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      position: 'absolute',
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        bottom: 0,
        borderRadius: 0,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
      },
    },
  })
);

export interface TruvuDialogProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  subtitle?: React.ReactNode;
  contentScrollable?: boolean;
  closeIcon?: React.ReactNode;
  headingAction?: React.ReactNode;
}

export function TruvuDialog({
  open,
  onClose,
  title,
  subtitle,
  headingAction,
  contentScrollable,
  closeIcon,
  children,
}: React.PropsWithChildren<TruvuDialogProps>) {
  const classes = useTruvuDialogStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      classes={{root: classes.container, paper: classes.paper}}
    >
      <IconButton sx={{alignSelf: 'flex-start', p: 0, mb: 2}} onClick={onClose}>
        {closeIcon ?? <Close fontSize="medium" />}
      </IconButton>
      {title != null ? (
        <TruvuMenu
          title={title}
          subtitle={subtitle}
          action={headingAction}
          mb={contentScrollable ? 3 : 2}
          alwaysSpaceBetween
        />
      ) : null}
      <Stack
        spacing="15px"
        pt={contentScrollable ? 1 : 0}
        pb={1}
        style={contentScrollable ? {overflow: 'auto'} : undefined}
      >
        {children}
      </Stack>
    </Dialog>
  );
}
