/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type TourCardActionsGenerateReelMutationVariables = {
    tourId: string;
};
export type TourCardActionsGenerateReelMutationResponse = {
    readonly tourGenerateReel: {
        readonly reel: {
            readonly id: string;
            readonly downloadURL: string;
        };
    };
};
export type TourCardActionsGenerateReelMutation = {
    readonly response: TourCardActionsGenerateReelMutationResponse;
    readonly variables: TourCardActionsGenerateReelMutationVariables;
};



/*
mutation TourCardActionsGenerateReelMutation(
  $tourId: ID!
) {
  tourGenerateReel(input: {tourId: $tourId}) {
    reel {
      id
      downloadURL
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tourId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "tourId",
            "variableName": "tourId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "TourGenerateReelPayload",
    "kind": "LinkedField",
    "name": "tourGenerateReel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Reel",
        "kind": "LinkedField",
        "name": "reel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "downloadURL",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TourCardActionsGenerateReelMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TourCardActionsGenerateReelMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c0ef1f4fbe5a7141fe5013a3383c3d32",
    "id": null,
    "metadata": {},
    "name": "TourCardActionsGenerateReelMutation",
    "operationKind": "mutation",
    "text": "mutation TourCardActionsGenerateReelMutation(\n  $tourId: ID!\n) {\n  tourGenerateReel(input: {tourId: $tourId}) {\n    reel {\n      id\n      downloadURL\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a096e55c710f7593044c6353f7826c76';
export default node;
