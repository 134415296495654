import React, {useState, useCallback} from 'react';
import {GoogleOAuthProvider, useGoogleLogin} from '@react-oauth/google';
import {ConfigService} from '../../utils';
import {TruvuButton} from './TruvuButton';
import GoogleSVG from '../../assets/svg/google.svg';
import {useAuthContext} from '../../context/AuthContext';
import {useHistory} from 'react-router';
import {TruvuMessageDialog} from '../dialog/TruvuMessageDialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface CustomState {
  inviteToken?: string;
  turnstileToken: string;
  referredBy?: string;
  acceptedTerms?: boolean;
}

const GoogleLoginButton = ({
  onValidateLogin,
  customState,
  gToken,
  buttonRef,
}: {
  onValidateLogin?: () => boolean;
  customState: CustomState;
  gToken?: string;
  buttonRef?: React.RefObject<HTMLButtonElement>;
}) => {
  const {handleLogin} = useAuthContext();
  const {push} = useHistory();

  const stateParam = btoa(JSON.stringify(customState));
  const [loginStatus, setLoginStatus] = useState<
    'idle' | 'loading' | 'success' | 'error'
  >('idle');

  const handleGoogleLogin = useCallback(
    async (code: string, state: string, gToken: string) => {
      setLoginStatus('loading');
      try {
        const response = await fetch(
          `${ConfigService.serverUri}/auth/google/user`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({code, state, gToken}),
          }
        );
        const data = await response.json();
        if (response.ok) {
          if (data && data.token && data.email) {
            handleLogin(data.email, data.token, 'google');
            push('/');
            setLoginStatus('success');
          } else {
            setLoginStatus('error');
          }
          // Store user data or token as needed
        } else {
          setLoginStatus('error');
        }
      } catch (error) {
        setLoginStatus('error');
      }
    },
    [handleLogin, push]
  );

  const login = useGoogleLogin({
    onSuccess: (response) => {
      handleGoogleLogin(response.code, stateParam, gToken ?? '');
    },
    onError: () => {
      setLoginStatus('error');
    },
    state: stateParam,
    flow: 'auth-code', // Use authorization code flow
  });

  const handleLoginAttempt = async () => {
    if (onValidateLogin) {
      const response = onValidateLogin();
      if (!response) {
        return;
      }
    }

    if (gToken != null && gToken !== '') {
      await handleGoogleLogin('', stateParam, gToken);
    } else {
      login();
    }
  };

  return (
    <>
      <TruvuMessageDialog
        title={'Something went wrong...'}
        variant={'error'}
        isOpen={loginStatus === 'error'}
        message={
          <Stack>
            <Typography variant="body1" gutterBottom>
              Unfortunately we were unable to log you in using your google
              account.
            </Typography>
            <Typography variant="body2" color="text.secondary">
              For more information contact{' '}
              <a href="mailto: support@truvu.app">support@truvu.app</a>
            </Typography>
          </Stack>
        }
        actions={
          <>
            <TruvuButton
              variant={'secondary'}
              onClick={() => {
                setLoginStatus('idle');
              }}
            >
              Close
            </TruvuButton>
          </>
        }
        onClose={() => {
          setLoginStatus('idle');
        }}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onOpen={() => {}}
      />
      <TruvuButton
        variant="default"
        sx={{mt: 1, bgcolor: '#EDEFF2'}}
        startIcon={<img height={'29px'} src={GoogleSVG} alt={'google'} />}
        onClick={handleLoginAttempt}
        loading={loginStatus === 'loading'}
        loadingText={'Authenticating...'}
        ref={buttonRef}
        disabled={
          customState.turnstileToken == null ||
          customState.turnstileToken === ''
        }
      >
        Log in with Google
      </TruvuButton>
    </>
  );
};

const GoogleLoginButtonWrapper = ({
  onValidateLogin,
  customState,
  gToken,
  buttonRef,
}: {
  onValidateLogin?: () => boolean;
  customState: CustomState;
  gToken?: string;
  buttonRef?: React.RefObject<HTMLButtonElement>;
}) => {
  return (
    <GoogleOAuthProvider clientId={ConfigService.googleClientId}>
      <GoogleLoginButton
        onValidateLogin={onValidateLogin}
        customState={customState}
        gToken={gToken}
        buttonRef={buttonRef}
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleLoginButtonWrapper;
