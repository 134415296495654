import {TruvuMenu} from '../../../components/TruvuMenu';
import {Stack, Typography} from '@mui/material';
import React, {useCallback, useMemo, useState} from 'react';
import {TruvuTextField} from '../../../components/textField';
import graphql from 'babel-plugin-relay/macro';
import {useMutation} from 'relay-hooks';
import {Form, FormOnSubmit} from '../../../components/form/Form';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {useSnackbarContext} from '../../../context/SnackbarContext';
import {UploadThumbnail} from './UploadThumbnail';
import {TourCard_tour} from '../../../__generated__/TourCard_tour.graphql';
import {TourEditDetailsMutation} from '../../../__generated__/TourEditDetailsMutation.graphql';
import {TourNewThumbnail} from './TourNewThumbnail';

interface TourDetailsProps {
  tour: TourCard_tour;
  onCompleted: () => void;
  onCancel: () => void;
}

export function TourEditDetails({
  tour,
  onCompleted,
  onCancel,
}: TourDetailsProps) {
  const {notify} = useSnackbarContext();
  const [newThumbnail, setNewThumbnail] = useState<File | null>(null);

  const [tourUpdate] = useMutation<TourEditDetailsMutation>(
    graphql`
      mutation TourEditDetailsMutation($input: TourSaveInput!) {
        tourSave(input: $input) {
          tour {
            id
            title
            description
            videoURL
          }
        }
      }
    `,
    {
      onCompleted: () => {
        notify({message: 'Successful', variant: 'success', position: 'center'});
        onCompleted();
      },
    }
  );

  const initialValues = useMemo<{
    title?: string;
    description?: string;
  }>(
    () => ({
      title: tour.title,
      description: tour.description,
    }),
    [tour]
  );

  const onSubmit = useCallback<
    FormOnSubmit<{title?: string; description?: string}>
  >(
    async (values, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      try {
        await tourUpdate({
          variables: {
            input: {
              id: tour.id,
              title: values.title ?? tour.title,
              description: values.description ?? tour.description,
            },
          },
        });
      } catch (e) {
        notify({variant: 'error', message: 'Failed to update tour details'});
      } finally {
        formikHelpers.setSubmitting(false);
      }
    },
    [notify, tour, tourUpdate]
  );

  if (newThumbnail != null) {
    return (
      <TourNewThumbnail
        tourId={tour.id}
        newThumbnail={newThumbnail}
        onCompleted={onCompleted}
      />
    );
  }

  return (
    <>
      <TruvuMenu
        title={tour.title}
        action={
          <UploadThumbnail
            src={tour.thumbnailURL}
            onChangeThumbnail={(file) => {
              setNewThumbnail(file);
            }}
          />
        }
        TitleProps={{
          className: 'truncate description',
        }}
        alwaysSpaceBetween
      />
      <Stack spacing={1.5}>
        <Typography variant={'h5'} fontWeight={600}>
          Tour Info
        </Typography>
        <Form initialValues={initialValues} onSubmit={onSubmit}>
          <TruvuTextField name="title" label="Title" formikField />
          <TruvuTextField
            name="description"
            label="Description"
            multiline
            formikField
          />
          <TruvuButton sx={{mt: 2}} formikSubmit>
            Save
          </TruvuButton>
          <TruvuButton variant="secondary" onClick={onCancel}>
            Cancel
          </TruvuButton>
        </Form>
      </Stack>
    </>
  );
}
