import {Box} from '@mui/material';
import ReactCrop, {Crop} from 'react-image-crop';
import React from 'react';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {useMutation} from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';
import {useSnackbarContext} from '../../../context/SnackbarContext';
import 'react-image-crop/dist/ReactCrop.css';
import {TourNewThumbnailMutation} from '../../../__generated__/TourNewThumbnailMutation.graphql';

interface TourNewThumbnailProps {
  tourId: string;
  newThumbnail: File | null;
  onCompleted: () => void;
}

export function TourNewThumbnail({
  tourId,
  newThumbnail,
  onCompleted,
}: TourNewThumbnailProps) {
  const {notify} = useSnackbarContext();
  const [
    tourUpdateThumbnailURL,
    {loading, error},
  ] = useMutation<TourNewThumbnailMutation>(
    graphql`
      mutation TourNewThumbnailMutation($input: TourUpdateThumbnailURLInput!) {
        tourUpdateThumbnailURL(input: $input) {
          tour {
            id
            thumbnailURL
          }
        }
      }
    `,
    {
      onCompleted: () => {
        notify({message: 'Successful', variant: 'success', position: 'center'});
        onCompleted();
      },
    }
  );

  const [crop, setCrop] = React.useState<Crop>({
    unit: '%',
    width: 0,
    height: 0,
    aspect: 16 / 9,
    x: 0,
    y: 0,
  });
  const imgRef = React.useRef<HTMLImageElement>();

  const previewCanvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const [blob, setBlob] = React.useState<Blob | null>();
  const readerRef = React.useRef(new FileReader());

  React.useEffect(() => {
    if (previewCanvasRef.current != null) {
      previewCanvasRef.current?.toBlob(
        async (blob) => {
          setBlob(blob);
        },
        'image/png',
        1
      );
    }
  }, []);

  const onComplete = React.useCallback((crop: Crop) => {
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;

    if (image && canvas && crop != null) {
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const ctx = canvas.getContext('2d');
      const pixelRatio = window.devicePixelRatio;

      canvas.width = crop.width * pixelRatio * scaleX;
      canvas.height = crop.height * pixelRatio * scaleY;

      if (ctx) {
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx?.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY
        );
      }
      previewCanvasRef.current?.toBlob(
        async (blob) => {
          setBlob(blob);
        },
        'image/png',
        1
      );
    }
  }, []);
  const onSubmit = React.useCallback(async () => {
    try {
      if (blob) {
        await tourUpdateThumbnailURL({
          variables: {
            input: {
              id: tourId,
              thumbnailURL: blob,
            },
          },
        });
      } else {
        await tourUpdateThumbnailURL({
          variables: {
            input: {
              id: tourId,
              thumbnailURL: newThumbnail,
            },
          },
        });
      }
    } catch (e) {
      notify({variant: 'error', message: 'Unable to update thumbnail'});
    }
  }, [blob, newThumbnail, notify, tourId, tourUpdateThumbnailURL]);

  React.useEffect(() => {
    // readerRef.current.addEventListener('load', () => {});
    if (newThumbnail) readerRef.current.readAsDataURL(newThumbnail);
    return () => undefined;
  }, [newThumbnail]);

  return (
    <Box
      flexDirection="column"
      display="flex"
      justifyContent="center"
      alignItems="center"
      overflow={'hidden'}
    >
      {readerRef.current.result?.toString() && (
        <ReactCrop
          style={{marginTop: '10px'}}
          src={readerRef.current.result?.toString()}
          onImageLoaded={(img) => {
            imgRef.current = img;
          }}
          crop={crop}
          onChange={setCrop}
          onComplete={onComplete}
        />
      )}
      <div>
        <canvas
          ref={previewCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          style={{
            width: Math.round(crop?.width ?? 0),
            height: Math.round(crop?.height ?? 0),
            display: 'none',
          }}
        />
      </div>
      <TruvuButton
        sx={{mb: 2}}
        onClick={onSubmit}
        loading={loading}
        loadingText="Updating Thumbnail"
        variant={error != null ? 'danger' : 'primary'}
      >
        {error != null ? 'Update Failed' : 'Update Thumbnail'}
      </TruvuButton>
    </Box>
  );
}
