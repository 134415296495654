import React, {useCallback, useMemo} from 'react';
import * as yup from 'yup';
import graphql from 'babel-plugin-relay/macro';
import {Form, FormOnSubmit} from '../../../components/form/Form';
import {TruvuTextField} from '../../../components/textField';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {useFragment} from 'relay-hooks';
import {useMutation} from 'react-relay-mutation';
import {ProfileEditDetailsMutation} from '../../../__generated__/ProfileEditDetailsMutation.graphql';
import {useSnackbarContext} from '../../../context/SnackbarContext';
import {ProfileEditDetails_user$key} from '../../../__generated__/ProfileEditDetails_user.graphql';
import {parsePhoneNumber} from 'react-phone-number-input';
import {PhoneNumberFormikField} from '../../../components/textField/PhoneNumberTextfield';

// import 'react-international-phone/style.css';
interface ProfileEditDetailsForm {
  name: string;
  surname: string;
  contactNumber: string;
}

interface ProfileEditDetailsProps {
  userRef: ProfileEditDetails_user$key;
  onCompleted: () => void;
  onCancel?: () => void;
  newProfile: File | null;
}

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .max(30, 'Maximum characters reached')
    .required('Name is a required field.'),
  surname: yup
    .string()
    .max(30, 'Maximum characters reached')
    .required('Surname is a required field.'),
  contactNumber: yup.string().required('Contact Number is a required field.'),
});

const fragment = graphql`
  fragment ProfileEditDetails_user on User {
    id
    profile {
      id
      name
      surname
      email
      contactNumber
      profileImageURL
    }
  }
`;

export function ProfileEditDetails({
  userRef,
  onCompleted,
  onCancel,
  newProfile,
}: ProfileEditDetailsProps) {
  const {notify} = useSnackbarContext();
  const user = useFragment(fragment, userRef);
  const [profileSave] = useMutation<ProfileEditDetailsMutation>(
    graphql`
      mutation ProfileEditDetailsMutation($input: UserSaveInput!) {
        userSave(input: $input) {
          user {
            profile {
              name
              surname
              contactNumber
              profileImageURL
            }
          }
        }
      }
    `,
    {
      onCompleted: () => {
        // notify({message: 'Success', variant: 'success', position: 'center'});
      },
    }
  );

  const handleSubmit = useCallback<FormOnSubmit<ProfileEditDetailsForm>>(
    async (values, {setSubmitting, setFieldError}) => {
      setSubmitting(true);
      const parsedPhoneNumber = parsePhoneNumber(values.contactNumber ?? '');

      if (values.contactNumber != null && !parsedPhoneNumber?.isValid()) {
        setFieldError('contactNumber', 'Invalid phone number');
        return;
      }

      const code = parsedPhoneNumber?.countryCallingCode;
      const nationalNumber = parsedPhoneNumber?.nationalNumber;
      const formattedPhoneNumber =
        code != null && nationalNumber != null
          ? `+${code} ${nationalNumber}`
          : null;

      if (formattedPhoneNumber == null) {
        setFieldError('contactNumber', 'Contact number is required');
        return;
      }
      try {
        await profileSave({
          variables: {
            input: {
              name: values.name,
              surname: values.surname,
              contactNumber: formattedPhoneNumber,
              ...(newProfile ? {profileImage: newProfile} : {}),
            },
          },
          onCompleted,
        });
      } catch (e) {
        notify({
          message: 'Failed to save profile',
          variant: 'error',
          position: 'right',
        });
      } finally {
        setSubmitting(false);
      }
    },
    [profileSave, newProfile, onCompleted, notify]
  );

  const initialValues = useMemo<ProfileEditDetailsForm>(() => {
    if (user?.profile != null) {
      return {
        name: user.profile?.name ?? '',
        surname: user.profile?.surname ?? '',
        email: user.profile?.email ?? '',
        contactNumber: user.profile?.contactNumber ?? '',
      };
    }
    return {
      name: '',
      surname: '',
      contactNumber: '',
    };
  }, [user?.profile]);

  return (
    <Form<ProfileEditDetailsForm>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <TruvuTextField name="name" label="Name" formikField />
      <TruvuTextField name="surname" label="Surname" formikField />
      <TruvuTextField name="email" label="E-mail" formikField disabled={true} />
      <PhoneNumberFormikField name="contactNumber" label="Contact Number" />
      <TruvuButton variant="primary" formikSubmit>
        Save
      </TruvuButton>
      {onCancel != null && (
        <TruvuButton variant="secondary" onClick={onCancel}>
          Cancel
        </TruvuButton>
      )}
    </Form>
  );
}
