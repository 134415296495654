import React, {ReactNode} from 'react';
import {
  Stack,
  StackProps,
  Typography,
  TypographyOwnProps,
  TypographyProps,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {addShouldNotForwardProps} from '../utils/addShouldNotForwardProps';

interface TruvuMenuProps extends StackProps {
  title: string;
  subtitle?: React.ReactNode;
  subtitleColor?: string;
  subMenu?: boolean;
  sticky?: boolean;
  alwaysSpaceBetween?: boolean;
  variant?: TypographyOwnProps['variant'];
  action?: ReactNode;
  TitleProps?: TypographyProps;
}

interface StyledHeadingMenuProps {
  $sticky?: boolean;
  $subMenu?: boolean;
  $alwaysSpaceBetween?: boolean;
}

const StyledHeadingMenu = styled(Stack, {
  shouldForwardProp: addShouldNotForwardProps(
    '$sticky',
    '$subMenu',
    '$alwaysSpaceBetween'
  ),
})<StyledHeadingMenuProps>(
  ({theme, $sticky, $alwaysSpaceBetween, $subMenu}) => ({
    position: $sticky ? 'sticky' : 'relative',

    ...($subMenu
      ? {padding: '10px 0', top: '75px'}
      : $sticky
      ? {height: '75px', top: 0}
      : {paddingBottom: '10px'}),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2.5),
    zIndex: theme.zIndex.appBar,
    backdropFilter: 'blur(15px)',
    webkitBackdropFilter: 'blur(15px)',
    /* For Safari */
    backgroundColor: 'rgba(247, 247, 247, 0.9)',
    // backgroundColor: 'grey',
    [theme.breakpoints.up('md')]: {
      justifyContent: $alwaysSpaceBetween ? 'space-between' : 'flex-start',
    },
  })
);

export const TruvuMenu = React.memo(function TruvuMenuComponent({
  title,
  subtitle,
  subtitleColor,
  alwaysSpaceBetween,
  variant = 'h1',
  action,
  sticky,
  subMenu,
  TitleProps,
  ...stackProps
}: TruvuMenuProps) {
  return (
    <StyledHeadingMenu
      $sticky={sticky}
      $alwaysSpaceBetween={alwaysSpaceBetween}
      $subMenu={subMenu}
      {...stackProps}
    >
      <Stack>
        <Typography variant={variant} fontWeight={700} {...TitleProps}>
          {title}
        </Typography>
        {typeof subtitle === 'string' ? (
          <Typography
            variant={'subtitle1'}
            color={subtitleColor ?? 'primary'}
            fontWeight={700}
          >
            {subtitle}
          </Typography>
        ) : (
          subtitle
        )}
      </Stack>
      {action}
    </StyledHeadingMenu>
  );
});
