import React from 'react';
import {Terms} from '../components/TermsComponent';

export const termsAndConditionsOfUse: Terms = [
  {
    id: '1.1',
    term: (
      <>
        This document sets out the terms and conditions (&quot;Terms&quot;) of
        Truvu (&quot;Service Provider&quot;) pertaining to the access and use of
        the information, products, services and functions provided on{' '}
        <a href="https://www.truvu.app" target="_blank" rel="noreferrer">
          www.truvu.app{' '}
        </a>
        (&quot;Website&quot;).
      </>
    ),
  },
  {
    id: '1.2',
    term: `Should any person that accesses the Website you ("you" or "user") disagree with any of the Terms, you must refrain from accessing the Website and/or using our services.`,
  },
  {
    id: '1.3',
    term: `If you are under the age of 18, you must obtain your parents' or legal guardians' advance authorisation, permission and consent to be bound by these Terms before purchasing any products or services`,
  },
  {
    id: '1.4',
    term: `Service Provider reserves the right, in its sole discretion, to amend and/or replace any of, or the whole of, the Terms. Such amendments shall supersede and replace any previous Terms and shall be made available on the Website. Each time a user accesses the Website and/or uses the services, the user shall be deemed to have consented, by such access and/or use, to the Terms, as amended and/or replaced by Service Provider from time to time. If you are not satisfied with the amended Terms, you should refrain from using the Website.`,
  },
  {
    id: '1.5',
    term: `We will however give you prior notice where we have collected personal information from you and the purpose for which we collected that information, is affected by the intended amendment.`,
  },
  {
    id: '1.6',
    term: `If there is anything in these Terms that you do not understand, then please contact us as soon as possible as per the details included in terms of paragraph 11. Please note that calls to us are charged at national rates and may be monitored for training, security and quality assurance purposes.`,
  },
];
