import React from 'react';
import {TruvuContainer} from '../components/containers/TruvuContainer';
import {Stack} from '@mui/material';
import {SubscriptionCard} from '../modules/subscriptions/components/SubscriptionCard';
import Typography from '@mui/material/Typography';
import {SubscriptionsPageQuery} from '../__generated__/SubscriptionsPageQuery.graphql';
import RelayRenderer from '../RelayRenderer';
import graphql from 'babel-plugin-relay/macro';
import {useHistory} from 'react-router';

const query = graphql`
  query SubscriptionsPageQuery {
    me {
      user {
        company {
          license {
            planName
            quantity
          }
        }
      }
    }
    stripeProduct {
      productId
      priceTiers {
        priceId
        flatAmount
        unitAmount
        upTo
        currencyCode
      }
    }
  }
`;
export function SubscriptionsPage() {
  const {push} = useHistory();

  const labels = ['Free', 'Entry', 'Basic', 'Pro'];
  const descriptions = [
    'For checking out our tours and concluding we are the best',
    'For individual realtors or small teams, basic features',
    'Growing agencies, more listings and storage',
    'Mid market businesses, advanced APIs and support',
  ];

  return (
    <RelayRenderer<SubscriptionsPageQuery> query={query}>
      {(data) => (
        <TruvuContainer
          onGoBack={() => {
            push('/account');
          }}
        >
          <Typography variant="h1" color="text.primary" my={2}>
            Subscription plans
          </Typography>
          <Stack direction="row" spacing={1.5} flexWrap="wrap" useFlexGap>
            {data.stripeProduct?.priceTiers &&
              data.stripeProduct?.priceTiers.map((tier, index) => (
                <SubscriptionCard
                  key={tier.priceId}
                  priceId={tier.priceId}
                  isActivePlan={
                    data.me?.user?.company?.license?.quantity === tier.upTo
                  }
                  label={labels[index]}
                  description={descriptions[index]}
                  price={tier.flatAmount / 100}
                  activeToursCount={tier.upTo} // Assuming 'upTo' represents the number of active tours
                  uploadLimitCount={tier.upTo * 2} // Assuming 'unitAmount' represents the upload limit count
                  currencyCode={tier.currencyCode}
                />
              ))}
          </Stack>
        </TruvuContainer>
      )}
    </RelayRenderer>
  );
}
