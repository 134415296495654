import React, {useCallback, useMemo, useRef} from 'react';
import {usePagination, useRefetchable} from 'relay-hooks';
import {TourCard} from './TourCard';
import {
  ToursPageQuery,
  ToursPageQueryResponse,
} from '../../../__generated__/ToursPageQuery.graphql';
import {Tours_tours$key} from '../../../__generated__/Tours_tours.graphql';
import graphql from 'babel-plugin-relay/macro';
import {TruvuMenu} from '../../../components/TruvuMenu';
import Grid2 from '@mui/material/Unstable_Grid2';
import {TourLoadMoreCard} from './TourLoadMoreCard';
import {Tours_user$key} from '../../../__generated__/Tours_user.graphql';
import {
  TruvuAlert,
  TruvuAlertProps,
} from '../../../components/alerts/TruvuAlert';
import {ResendEmailActivation} from './ResendEmailActivation';
import {checkUploadLimit} from '../helpers/checkUploadLimit';
import {TruvuButton} from '../../../components/button/TruvuButton';
import {TourMemberCard} from './TourMemberCard';
import {FragmentRefs} from 'relay-runtime';

interface ToursLayoutProps {
  data: ToursPageQueryResponse;
}

const fragmentSpec = graphql`
  fragment Tours_tours on Query @refetchable(queryName: "ToursRefetchQuery") {
    tours(first: $count, after: $cursor) @connection(key: "Tours_tours") {
      edges {
        cursor
        node {
          id
          status {
            status
          }
          ...TourCard_tour
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

const userFragmentSpec = graphql`
  fragment Tours_user on User
  @refetchable(queryName: "ToursAlertsRefetchQuery") {
    id
    monthlyUploadedTours
    monthlyActiveTours
    topFiveTours {
      id
      title
      thumbnailURL
      views
      deactivatedViews
    }
    profile {
      email
    }
    company {
      invites {
        accepted
        createdAt
        email
        expiresAt
        user {
          user {
            profile {
              email
              name
              surname
            }
            topFiveTours {
              ...TourMemberCard_tour
              id
              views
            }
          }
        }
      }
      license {
        planName
        monthlyActiveTours
        monthlyUploadedTours
        uploadLimit
        quantity
      }
    }
  }
`;

export function Tours({data}: ToursLayoutProps) {
  const {
    data: {tours},
    refetch,
    loadNext,
    hasNext,
    isLoadingNext,
  } = usePagination<ToursPageQuery, Tours_tours$key>(fragmentSpec, data);
  const {data: user, refetch: refetchAlerts} = useRefetchable(
    userFragmentSpec,
    data.me?.user as Tours_user$key
  );
  /**  refetchDevicesTimeoutRef -> Used to reload the devices every (x) seconds */
  const refetchDevicesTimeoutRef = useRef<NodeJS.Timeout | undefined>();

  const refetchDevices = useCallback(async () => {
    if (refetchDevicesTimeoutRef.current != null) {
      clearInterval(refetchDevicesTimeoutRef.current);
    }
    try {
      refetch({});
    } finally {
      refetchDevicesTimeoutRef.current = setTimeout(async () => {
        await refetchDevices();
      }, 10000);
    }
  }, [refetch]);

  const processingTours = useMemo(
    () =>
      tours.edges?.filter(
        (edge) => edge?.node?.status.status.toLowerCase() !== 'processed'
      ),
    [tours.edges]
  );

  const processedTours = useMemo(
    () =>
      tours.edges?.filter(
        (edge) => edge?.node?.status.status.toLowerCase() === 'processed'
      ),
    [tours.edges]
  );

  const teamTours = useMemo(() => {
    return (
      user.company?.invites?.reduce(
        (
          acc: Array<{
            name: string;
            tours: ReadonlyArray<{
              readonly id: string;
              readonly views: number;
              readonly ' $fragmentRefs': FragmentRefs<'TourMemberCard_tour'>;
            } | null>;
          }>,
          invite
        ) => {
          if (
            invite.user?.user?.topFiveTours != null &&
            invite.user?.user?.topFiveTours.length > 0
          ) {
            acc.push({
              name:
                invite.user.user.profile?.name != null
                  ? `${invite.user.user.profile?.name} ${invite.user.user.profile?.surname}`
                  : invite.user.user.profile?.email ?? '-',
              tours: invite.user.user.topFiveTours,
            });
          }

          return acc;
        },
        []
      ) ?? []
    );
  }, [user.company?.invites]);

  const handleRefetchTours = useCallback(async () => {
    refetch({});
    refetchAlerts({});
  }, [refetch, refetchAlerts]);

  React.useEffect(() => {
    if (processingTours != null && processingTours.length > 0) {
      void refetchDevices();
      void refetchAlerts({});
    }
    return () => {
      if (refetchDevicesTimeoutRef.current != null) {
        clearInterval(refetchDevicesTimeoutRef.current);
      }
    };
  }, [processingTours, refetchAlerts, refetchDevices]);

  const {company, profile} = user;

  const alerts: TruvuAlertProps[] = [];

  const accountActivated = company?.license != null;

  if (!accountActivated) {
    alerts.push({
      label: 'Account status',
      message: 'Not activated',
      severity: 'error',
      popup: {
        label: 'Activate your account',
        message:
          'Please click the link in your email to activate your account.',
        actions: <ResendEmailActivation email={profile?.email ?? ''} />,
      },
    });
  }

  const {isActiveAvailable, isUploadAvailable} = checkUploadLimit({
    monthlyActiveTours: company?.license?.monthlyActiveTours,
    monthlyUploadedTours: company?.license?.monthlyUploadedTours,
    uploadLimit: company?.license?.uploadLimit,
    quantity: company?.license?.quantity,
  });

  if (accountActivated && !isActiveAvailable) {
    alerts.push({
      label: 'Membership',
      message: 'No active tours left',
      severity: 'error',
      popup: {
        label: 'Upgrade your account',
        message: 'Upgrade to a larger plan in order to upload more tours',
        actions: (
          <TruvuButton variant="danger" to="/account/subscriptions">
            Upgrade plan
          </TruvuButton>
        ),
      },
    });
  }
  if (accountActivated && !isUploadAvailable) {
    alerts.push({
      label: 'Membership',
      message: 'No monthly uploads left',
      severity: 'error',
      popup: {
        label: 'Upgrade your account',
        message: 'Upgrade to a larger plan in order to upload more tours',
        actions: (
          <TruvuButton variant="danger" to="/account/subscriptions">
            Upgrade plan
          </TruvuButton>
        ),
      },
    });
  }

  return (
    <>
      {alerts.length > 0 && (
        <>
          <TruvuMenu title="Alerts" variant="h2" subMenu sticky />
          <Grid2 spacing="10px" container>
            {alerts.map((alert, i) => (
              <Grid2 key={i} xs={12} sm={6} md={4}>
                <TruvuAlert {...alert} />
              </Grid2>
            ))}
          </Grid2>
        </>
      )}
      {processingTours != null && processingTours.length > 0 && (
        <>
          <TruvuMenu title="Processing Tours" variant="h2" subMenu sticky />
          <Grid2 spacing="10px" container>
            {processingTours.map((edge) =>
              edge?.node ? (
                <Grid2 xs={12} sm={6} md={4} key={edge.node.id}>
                  <TourCard
                    tourRef={edge.node}
                    refetchTours={handleRefetchTours}
                  />
                </Grid2>
              ) : null
            )}
          </Grid2>
        </>
      )}
      {(alerts.length > 0 ||
        (processingTours != null && processingTours.length > 0)) && (
        <TruvuMenu title="Truvu Tours" variant="h2" subMenu sticky />
      )}
      <Grid2 spacing="10px" container>
        {processedTours?.map((edge) =>
          edge?.node ? (
            <Grid2 xs={12} sm={6} md={4} key={edge.node.id}>
              <TourCard tourRef={edge.node} refetchTours={handleRefetchTours} />
            </Grid2>
          ) : null
        )}
        {hasNext && (
          <Grid2 xs={12} sm={6} md={4}>
            <TourLoadMoreCard
              isLoadingNext={isLoadingNext}
              loadNext={loadNext}
            />
          </Grid2>
        )}
      </Grid2>
      {teamTours.length > 0 && (
        <TruvuMenu title="Team Tours" variant="h2" subMenu sticky />
      )}
      <Grid2 spacing="10px" container>
        {teamTours?.map(({name, tours}) =>
          tours.map(
            (tour) =>
              tour != null && (
                <Grid2 xs={12} sm={6} md={4} key={tour.id}>
                  <TourMemberCard tourRef={tour} userName={name} />
                </Grid2>
              )
          )
        )}
      </Grid2>
    </>
  );
}
